import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import { useRecoilState } from 'recoil';

import Layout from 'layout';
// UI
import LoadingPage from 'ui/LoadingPage';
import MaintenancePage from 'ui/MaintenancePage';
// Import from State
import { isLoggedIn as loginState, profileState, configurationState, maintenanceState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';

// Lazy - Booking Routes
const ListReports = React.lazy(() => import('components/Reports/List'));
const ViewReport = React.lazy(() => import('components/Reports/View'));

function ReportsPage() {
  const [profile, setProfile] = useRecoilState(profileState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [, setConfiguration] = useRecoilState(configurationState);
  const [maintenance, setMaintenance] = useRecoilState(maintenanceState);

  // Check Login Status of User On First Run
  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    setMaintenance({
      online: profile.options.maintenance.online,
      message: profile.options.maintenance.message || 'This application is currently offline for maintenance.',
      isAdmin: profile.role.value === 'Administrator'
    });
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }

    if (isLoggedIn) {
      if (!profile?.options?.general?.reports) {
        navigate('/booking/create');
        return;
      }
      return;
    }
    initialize();
  }, []);

  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout
          showLogoutButton
          isMaintenanceMode={!maintenance.online}
          isAdmin={profile?.role?.value === 'Administrator'}
          isConfigRoute={false}
        >
          <div className="w-full">
            <div className="pl-4 pr-4 pt-6 md:pl-8 md:pr-8 xl:pr-16 mb-20">
              <React.Suspense fallback="">
                <Router>
                  <ListReports path="/report/list" />
                  <ViewReport path="/report/:id" />
                </Router>
              </React.Suspense>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default ReportsPage;
